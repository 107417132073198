import React from "react"
import logo from "../../images/new-footer/logo.svg"
import lucapayLogoImage from "../../images/luca-pay/lucapay-logo.svg";
import ReactGA from "react-ga";

export default function NewFooter({ toggleContactModal, noDemo = false, lucapay = false, page, latestFooter }) {
  // console.log("nodemo", noDemo)
  const showLatestFooter = lucapay || page === "Partner Page" || latestFooter;
  const requestDemoClick = () => {
    if(page){
      if(page === "Myob"){
        ReactGA.event({
          category: `${page} Page_Request demo3_White button`,
          action: `${page} Page_Request demo3_White button clicked`
        });
      } else {
        ReactGA.event({
          category: `${page}_Request demo_White button`,
          action: `${page}_Request demo_White button clicked`
        });
      }
    }
    toggleContactModal();
  }
  const askUsNowClick = () => {
    if(page){
      ReactGA.event({
        category: `${page}_Ask us how_White button`,
        action: `${page}_Ask us how_White button clicked`
      });
    }
    toggleContactModal();
  }
  return (
    <section className="new-footer bg-white  sm:mt-16">
      <div className="container flex flex-col sm:flex-row items-center justify-around">
        <div className="mx-10   order-2 sm:order-1">
          <div className="text-brand sm:text-left">
            {lucapay ?
                <a href="/" className="mx-auto sm:ml-0 lucapay-logo logo flex items-center">
                  <img src={lucapayLogoImage} alt="logo" className="pr-3"/>
                  <div className="text-neutral-3 whitespace-no-wrap ml-3 sm:text-16 text-14">
                    Powered by LUCA Plus
                  </div>
                </a>
                : <img src={logo} alt="logo" className="mx-auto sm:ml-0" />}
            <div className=" footer-address mt-6 sm:text-left">
              121 King Street,
              <br />
              Melbourne, Victoria, 3000
              <br />
              <span className="font-semibold">T</span> : <a href="tel:+61 (03) 9005 5308">+61 (03) 9005 5308</a>
              <br />
              <span className="font-semibold">E</span> : <a href="mailto:support@lucaplus.com">support@lucaplus.com</a>
            </div>
          </div>
          <div className="font-medium mt-4 mb-2 sm:text-left"></div>
          <ul className="flex flex-row items-center justify-between mx-4 sm:-ml-6 ">
            <li>
              <a
                href="https://www.linkedin.com/company/LucaPlus/"
                target="_blank"
                className="no-underline inline-flex items-center  px-2 py-2 text-gray-26 hover:text-brand rounded   font-bold md:ml-2 text-xl"
              >
                <svg className="wh-35  fill-current" viewBox="0 0 36 36">
                  <defs />
                  <rect x=".256" y=".51" width="35" height="35" rx="17.5" />
                  <path
                    d="M27.472 26.268a71.333 71.333 0 00-3.418 0c-.246 0-.3-.055-.3-.301v-6.098c0-.465-.028-.93-.165-1.367-.41-1.422-2.05-1.941-3.226-.984-.629.492-.875 1.176-.875 1.996v5.742c0 .246-.027.492 0 .766.027.219-.082.273-.273.246h-3.5c-.22 0-.274-.055-.274-.274.027-1.722.027-3.445.027-5.195 0-2.133 0-4.265-.027-6.371 0-.246.055-.3.273-.3h3.5c.22 0 .274.054.274.273v1.394l.137-.136c1.066-1.56 2.57-1.997 4.347-1.723 2.051.328 3.364 1.75 3.692 3.937.082.52.11 1.04.11 1.559v6.535c0 .219-.056.3-.302.3zM13.253 20.198v5.742c0 .246-.054.328-.3.328-1.149-.027-2.297 0-3.446 0-.219 0-.273-.055-.273-.273V14.4c0-.191.054-.274.273-.274h3.527c.247 0 .274.082.274.301-.055 1.914-.055 3.828-.055 5.77zM13.445 10.955c-.274 1.094-1.395 1.723-2.68 1.504-1.367-.218-2.133-1.558-1.64-2.87.328-.821 1.12-1.313 2.132-1.286 1.532-.027 2.543 1.203 2.188 2.652z"
                    fill="#fff"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/LucaPlusAU/"
                target="_blank"
                className="no-underline inline-flex items-center  px-2 py-2 text-gray-26 hover:text-brand  rounded   font-bold md:ml-2 text-xl"
              >
                <svg className="wh-35 fill-current" viewBox="0 0 36 36">
                  <defs />
                  <path d="M.617 17.814c0-9.664 7.836-17.5 17.5-17.5 9.665 0 17.5 7.836 17.5 17.5 0 9.665-7.835 17.5-17.5 17.5-9.664 0-17.5-7.835-17.5-17.5zm17.5-9.33c-2.533 0-2.854.012-3.85.055-.995.047-1.67.205-2.264.436a4.579 4.579 0 00-1.653 1.076 4.58 4.58 0 00-1.077 1.654c-.23.594-.389 1.273-.436 2.264-.042.996-.055 1.312-.055 3.85 0 2.533.013 2.85.055 3.845.047.995.205 1.67.436 2.264.24.616.56 1.133 1.077 1.654a4.526 4.526 0 001.653 1.076c.594.231 1.274.39 2.265.436.995.047 1.311.056 3.85.056 2.533 0 2.849-.013 3.849-.056.99-.047 1.67-.205 2.264-.436a4.578 4.578 0 001.653-1.076 4.527 4.527 0 001.077-1.654c.23-.594.389-1.273.436-2.264.043-.995.056-1.312.056-3.85 0-2.533-.013-2.854-.056-3.849-.047-.991-.205-1.67-.436-2.264a4.579 4.579 0 00-1.076-1.654A4.579 4.579 0 0024.23 8.97c-.594-.23-1.273-.388-2.269-.435-.995-.043-1.311-.052-3.845-.052zm4.982 3.23c.62 0 1.12.5 1.12 1.119 0 .62-.5 1.12-1.12 1.12-.62 0-1.12-.5-1.12-1.12 0-.62.5-1.12 1.12-1.12zm-4.982 1.307a4.792 4.792 0 014.794 4.793 4.792 4.792 0 01-4.794 4.794 4.795 4.795 0 010-9.587zm0 1.683a3.11 3.11 0 100 6.222 3.11 3.11 0 000-6.222z" />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/LucaPlusAU"
                target="_blank"
                className="no-underline inline-flex items-center  px-2 py-2 text-gray-26 hover:text-brand  rounded   font-bold md:ml-2 text-xl"
              >
                <svg className="wh-35 fill-current" viewBox="0 0 36 36">
                  <defs />
                  <rect x=".713" y=".629" width="35" height="35" rx="17.5" />
                  <path
                    d="M26.79 14.346v.547c0 5.66-4.321 12.195-12.196 12.195-2.434 0-4.676-.71-6.59-1.914.328.028.684.055 1.012.055a8.677 8.677 0 005.332-1.832 4.243 4.243 0 01-3.993-2.98c.274.054.52.081.82.081.384 0 .766-.054 1.122-.164a4.256 4.256 0 01-3.445-4.183v-.055c.574.328 1.23.52 1.941.547a4.294 4.294 0 01-1.914-3.582c0-.793.219-1.531.574-2.16a12.197 12.197 0 008.832 4.484 3.88 3.88 0 01-.11-.984 4.283 4.283 0 014.294-4.293c1.23 0 2.351.52 3.117 1.34a8.713 8.713 0 002.734-1.04c-.328 1.012-1.011 1.833-1.886 2.38a8.211 8.211 0 002.46-.684 7.835 7.835 0 01-2.105 2.242z"
                    fill="#fff"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href={`${lucapay ? "https://www.facebook.com/LUCAPay" : "https://www.facebook.com/LucaPlusAU/"}`}
                target="_blank"
                className="no-underline inline-flex items-center  px-2 py-2 text-gray-26 hover:text-brand rounded   font-bold md:ml-2 text-xl"
              >
                <svg className="wh-35 fill-current" viewBox="0 0 36 36">
                  <defs />
                  <path d="M18.371 0C8.698 0 .871 7.897.871 17.657s7.827 17.658 17.5 17.658 17.5-7.902 17.5-17.658C35.871 7.897 28.044 0 18.371 0zm4.695 17.565H20.03v11.057h-4.513V17.565h-2.301v-3.808h2.302v-2.323c0-3.063 1.29-4.926 4.882-4.926h3.036v3.808h-1.842c-1.381 0-1.473.557-1.473 1.486v1.858h3.406l-.46 3.905z" />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UC0652wQaMNRp1c9_I4ZdoAA"
                target="_blank"
                className="no-underline inline-flex items-center  px-2 py-2 text-gray-26 hover:text-brand  rounded   font-bold md:ml-2 text-xl"
              >
                <svg className="wh-35 fill-current" viewBox="0 0 36 36">
                  <defs />
                  <g clipPath="url(#clip0)">
                    <path d="M15.602 20.97L21.7 17.5l-6.098-3.47v6.94z" />
                    <path d="M17.988 0C8.323 0 .488 7.835.488 17.5S8.323 35 17.988 35s17.5-7.835 17.5-17.5S27.653 0 17.988 0zm11.18 23.153a2.931 2.931 0 01-2.064 2.076c-1.819.49-9.116.49-9.116.49s-7.296 0-9.116-.49a2.933 2.933 0 01-2.063-2.076c-.487-1.832-.487-5.653-.487-5.653s0-3.821.487-5.653a2.932 2.932 0 012.063-2.076c1.82-.49 9.116-.49 9.116-.49s7.297 0 9.116.49a2.932 2.932 0 012.063 2.076c.488 1.832.488 5.653.488 5.653s0 3.821-.488 5.653z" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <path
                        fill="#fff"
                        transform="translate(.488)"
                        d="M0 0h35v35H0z"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </li>
          </ul>

          <div className="x"></div>
        </div>
        {!noDemo && (
          <div className="py-10 px-10 order-1 sm:order-2 mtop">
            <p className="footer-desc">
              {lucapay ? "Our team is ready to provide you with an overview on how streamlined and financially effective LUCA Pay is for your business." :
                  "Our team at LUCA Plus is ready to provide you with an overview on how easy it is to use LUCA Plus to grow your business."}
            </p>
            <div className="mt-6">
              {showLatestFooter ?
                  <a
                      className="request-demo-btn "
                      onClick={askUsNowClick}
                  >
                    Ask Us How
                  </a>
                : <a
                href="#"
                className="request-demo-btn "
                onClick={requestDemoClick}
              >
                Request a Demo
              </a>}
            </div>
          </div>
        )}

        <div className="sm:-mt-2 mx-10 mt-4 footer-links order-3 flex flex-col sm:flex-row links items-center sm:items-start justify-between">
          <ul className="sm:items-start   flex  sm:flex-col items-center justify-between ">
            <li className="mt-4 sm:mt-0 capitalize">
              <a
                href="/about/"
                className="no-underline hover:text-gray-26    py-1 px-2"
              >
                About us
              </a>
            </li>
            {!showLatestFooter && <li className="mt-4 sm:mt-6 capitalize">
              <a
                href="/pricing/"
                className="no-underline hover:text-gray-26  rounded   py-1 px-2"
              >
                Pricing
              </a>
            </li>}
            <li className="mt-4 sm:mt-6 capitalize">
              <a
                href={`${lucapay ? "https://meetings.hubspot.com/leo-huang" : "/contact/"}`}
                className="no-underline hover:text-gray-26 rounded   py-1 px-2"
              >
                Contact us
              </a>
            </li>
            {page !== "Partner Page" &&
            <li className="mt-4 sm:mt-6 capitalize">
              <a
                href="https://blog.lucaplus.com/"
                className="no-underline hover:text-gray-26 rounded   py-1 px-2"
              >
                Blog
              </a>
            </li>}
            {showLatestFooter && <li className="mt-4  sm:mt-0 capitalize block sm:hidden">
              <a
                  href="https://lucaplus.zendesk.com/hc/en-us"
                  className="no-underline hover:text-gray-26 rounded   py-1 px-2"
              >
                Support
              </a>
            </li>}
          </ul>
          <ul className="sm:items-start   flex  sm:flex-col items-center justify-between ">
            <li className="mt-4  sm:mt-0 capitalize">
              <a
                href="https://lucaplus.zendesk.com/hc/en-us"
                className={`${showLatestFooter && "sm:block hidden"} no-underline hover:text-gray-26 rounded px-2`}
              >
                Support
              </a>
            </li>
            <li className=" mt-4 sm:mt-6 min-w capitalize">
              <a
                href="https://docs.lucaplus.com/security-compliance-privacy"
                className="no-underline hover:text-gray-26 rounded   py-1 px-2"
              >
                Privacy & Security
              </a>
            </li>
            <li className="mt-4 sm:mt-6 min-w">
              <a
                href="https://docs.lucaplus.com/terms-of-service"
                className="no-underline hover:text-gray-26 rounded   py-1 px-2"
              >
                Terms of Service
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="mx-auto text-gray-25 text-xs leading-4 py-2 mt-12">
        © Copyright 2024 LUCAPLUS
      </div>
      <div className="bg-brand">
        <div className="mx-auto text-gray-25  text-xs leading-4 py-2">
          Made with ❤️ by LUCA Plus
        </div>
      </div>
    </section>
  )
}
