import { useState, useEffect } from 'react'
import _throttle from 'lodash.throttle'

let supportsPassive = false
try {
  if (typeof window !== 'undefined') {
    var opts = Object.defineProperty({}, 'passive', {
      get: function () {
        supportsPassive = true
      },
    })
    window.addEventListener('testPassive', null, opts)
    window.removeEventListener('testPassive', null, opts)
  }
} catch (e) { }

let getPosition = () => {
  try {
    if (typeof window !== 'undefined') {
      return ({
        x: window.pageXOffset,
        y: window.pageYOffset,
      })
    } else {
      return ({
        x: 0,
        y: 0,
      })
    }

  } catch (oError) {
    console.log(oError);
    return ({
      x: 0,
      y: 0,
    })
  }
}



let defaultOptions = {
  throttle: 100,
}

function useWindowScrollPosition(options) {
  let opts = Object.assign({}, defaultOptions, options)

  let [position, setPosition] = useState(getPosition())

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let handleScroll = _throttle(() => {
        setPosition(getPosition())
      }, opts.throttle)

      window.addEventListener(
        'scroll',
        handleScroll,
        supportsPassive ? { passive: true } : false
      )

      return () => {
        handleScroll.cancel()
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return position
}




export default useWindowScrollPosition;