import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useLayoutEffect } from "react"
// import LogoText from "../images/logo-footer.svg"
// import IntsImage from "../images/ins.svg"
// import FBImage from '../images/facebook.svg'
// import TwImage from "../images/twitter.svg"
// import YoutubeImage from "../images/youtube.svg"
// import LinkedinImage from "../images/linkedin.svg"
import classNames from "classnames";
import BacktopImage from "../images/back-top.svg"
import WhiteLogo from "../images/white-logo.svg"
import { getStartUrl } from "../constants/url";
import useWindowScrollPosition from "../hooks/window-scroll-position";
import { AnchorLink } from "gatsby-plugin-anchor-links"
// import { type } from "os";
import Modali, { useModali } from "./modal"
import NewFooter from "./new-footer";


function Footer({ simple = false, toggleExistingOrgModal, noDemo = false, nsip = false, myob = false, lucapay = false, page, latestFooter }) {
  const [contactModal, toggleContactModal] = useModali()
  const position = useWindowScrollPosition({
    throttle: 300,
  });

  // const [backtopShow, setBacktopShow] = useState(false);
  // useLayoutEffect(() => {

  // }, [])

  const gotop = (e) => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }
  let windowHeight = 0;
  if (typeof window !== 'undefined') {
    windowHeight = window.innerHeight || document.documentElement.clientHeight;
    // console.log("windowHeight", windowHeight, position.y >= windowHeight)
    // if (position.y >= windowHeight) {
    //   setBacktopShow(true)
    // } else {
    //   setBacktopShow(false)
    // }
  }
  const isShowBackTop = classNames(
    { 'show': position.y >= windowHeight }
  )

  const footerCls = simple ? "footer pt-0 sm:pt-auto bg-white " : "footer"
  return (
    <footer className={footerCls}>

      {!simple && (

        <div className="footer__top ">
          {/* <div className="footer__top__overlay"></div> */}
          <div className="container">
            <div className="footer__top__inner flex flex-col">
              <h3>
                Start Doing Things That Matter More
              </h3>
              <h3 className="semibold mt-4">
                TRY  <img src={WhiteLogo} alt="logo" className="white-logo" />  FOR FREE NOW
              </h3>
            </div>
            <div className="getstart-footer">

              {/* <input type="text" placeholder="Your Email" className="email-control" /> */}
              <div
                onClick={() => `${toggleExistingOrgModal? window.open(`/sign-up?plan=combined&lucapay=true&fromHomePage=true`, '_self') : page === 'Main page' ? window.open('/pricing') : window.open(getStartUrl)}`}
                className="nav-btn nav-btn--filled nav-btn--up mt-4 cursor-pointer"
              >GET STARTED</div>

            </div>
          </div>
        </div>
      )}

      <NewFooter toggleContactModal={toggleContactModal} noDemo={noDemo} lucapay={lucapay} page={page} latestFooter={latestFooter} />

      <a id="back-to-top" onClick={gotop} className={isShowBackTop}>
        <img src={BacktopImage} alt="gotop" onClick={gotop} />
      </a>
      <Modali.Modal {...contactModal} hubspot={true}>
        <div className="hero-hubspot">
          <iframe
            src={`https://meetings.hubspot.com/${lucapay ? "leo-huang" : 'ray-wang/meeting-with-luca-plus'}`}
            title="Introduction to LUCA Plus"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </div>
      </Modali.Modal>
    </footer>
  )
}

export default Footer;
